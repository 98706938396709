<template>
	<div class="justify-center">
        <div class="surface-card p-4 shadow-2 border-round w-full lg:w-6">
            <div class="text-center mb-5">
                <img src="images/coachera-logo-sm.png" alt="Image" height="50" class="mb-3">
                <div class="text-900 text-3xl font-medium mb-3">Welcome!</div>
            </div>

            <div class="flex signuptype">
                <Button label="Continue as user." icon="pi pi-user" class="p-button-outlined" v-on:click="submit(0)" ></Button>
                <br>
                <Button label="Continue as coach." icon="pi pi-user" class="p-button-outlined" v-on:click="submit(1)" ></Button>
            </div>
        </div>
	</div>
</template>

<script>
export default {
    methods: {
        submit(type) {
            if (type == 0) {
                this.$router.push("/signup");
            } else {
                this.$router.push("/signupcoach");
            }
        },
    }
}
</script>
<style>
.signuptype {
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: space-around;
}
</style>
